//const BASE = 'http://192.168.1.173:8888/sosclick/sosclick/public/api';
//const BASE = 'http://186.64.122.120/sosclick/public/api'
//const BASE = 'http://190.98.210.42/SOSCLICKNPH/public/api'
const BASE = 'https://190.98.210.42/apisos/public/api'

const prevEndpoints = 
    {
      REGISTRAR_CAMARA:'/asociar_camara',
      DATOS_UPDATE_CAMARA:'/datos_camara',
      LOGIN:'/auth/login',
      REGISTER:'/auth/signup',
      USUARIO_INFORMACION:'/details',
      LISTADO_USUARIOS:'/listado_de_usuarios',
      ENVIAR_INVITACION:'/enviar_invitacion_new',
      MIS_INVITADOS:'/mi_lista_invitacion',
      ELIMINAR_INVITADO:'/eliminar_invitado_new',
      UPDATE_TOKEN:'/token',
      ALERTA_GRUPO:'/enviar_alerta_new',
      REVISAR_MI_GRUPO:'/revisar_mi_grupo',
      SALIR_DE_RED:'/salir_de_grupo',
      LLAMADA_INICIO:'/notificar_llamada_grupo',
      ALERTA_WEB:'/alerta_web_general',
      OBTENER_ID:'/obtener_id'
    };

const endpoints = {
  login: '/auth/login',
  user: 'admin/user/info',
  createFirebaseAuthToken: 'admin/user/createFirebaseAuthToken',
  userDetails: 'admin/user/details',
  users: 'admin/users/getCompanyUsers',
  adminUsers: 'admin/user/usersAdminList',
  searchUsers: 'admin/user/searchUsers',
  superAdminUsers: 'admin/user/usersSuperAdminList',
  userDelete: 'admin/user/delete',
  updateUser: 'admin/user/updateInfoById',
  updateAdminUser: 'admin/user/updateAdminById',
  getAlerts: 'admin/alerts/alertsList',
	alertHeatmap: 'admin/alerts/alertHeatmap',
  getAlert: 'admin/alerts/alertData',
  setAlertInfo: 'admin/alerts/setAlertInfo',
  alertasActivas: 'admin/alerts/alertasActivas',
  closeAlert: 'admin/alerts/closeAlert',
  getCuadrantes: 'admin/cuadrantes/cuadrantesList',
  getCuadrante: 'admin/cuadrantes/getCuadrante',
  getPaises: 'admin/cuadrantes/getPaises',
  getComunas: 'admin/cuadrantes/getComunas',
  getRegiones: 'admin/cuadrantes/getRegiones',
  getRegionesByPais: 'admin/cuadrantes/getRegionesByPais',
  updateCuadrante: 'admin/cuadrantes/updateCuadrante',
  deleteCuadrante: 'admin/cuadrantes/deleteCuadrante',
  sendAndSavePublicidad: 'admin/publicidad/sendAndSave',
  publicidadList: 'admin/publicidad/publicidadList',
  publicidadById: 'admin/publicidad/publicidadById',
  cameraList: 'admin/tuya/getPanelTuyaCamera',
  newToken: 'admin/calls/newToken',
  sendSMS: 'admin/calls/sendSMS',
  getButtonData: 'admin/buttons/getButtonData',
  startMonitoreo: 'admin/user/startMonitoreoNotification',
  sendInactiveDeviceNotification: 'admin/user/sendInactiveDeviceNotification',
  deleteDispositivo: 'admin/dispositivos/deleteDispositivo',
  sendDailyCheckNotification: 'admin/user/dailyCheckNotification',
  sendNotificationMedicionIduam: 'admin/mediciones/startMedicion',
  getUserMediciones: 'admin/mediciones/getUserMediciones',
  createHolter: 'admin/holter/createHolter',
  startHolter: 'admin/holter/startHolter',
  stopHolter: 'admin/holter/stopHolter',
  getHolterData: 'admin/holter/holterData',
  deleteHolter: 'admin/holter/deleteHolter',
  startAtencion: 'videocalls/userEntersVideocall',
  endAtencion: 'admin/atenciones/endAtencion',
  endAtencionFixed: 'videocalls/userExitsVideocall',
  getFarmacosByQuery: 'admin/recetas/getFarmacosByQuery',
  saveReceta: 'admin/recetas/saveReceta',
  getExamenesByQuery: 'admin/examenes/getExamenesByQuery',
  createExamen: 'admin/examenes/createExamen',
  sendCustomNotification: 'admin/user/sendCustomNotificationAdmin',
  searchTrackingByDate: 'admin/tracking/searchTrackingByDate',
  activateTracking: 'admin/tracking/toogleTrackingNotification',
  createNote: 'admin/notes/createNote',
  uploadVideoRecord: 'admin/atenciones/uploadVideoRecord',
  createVideoCallRoom: '/admin/videocalls/createRoom',
  initVideoCallWithDevice: '/admin/videocalls/callFromAdminToDevice',
	getTuyaCameraVideoInfo: '/admin/tuya/getTuyaCameraVideoInfo',
	cameraPtzMove: '/admin/tuya/ptzMove',
  getCompanyConfig: '/company/getCompanyConfig',
  modifyClick2CallConfig: '/admin/config/modifyClick2CallConfig',
  modifyCompanyConfig: '/admin/config/updateCompanyConfig',
  getCompanyNews: '/company/getLocalNews',
  deleteLocalNews: '/admin/companies/deleteLocalNews',
  createLocalNews: '/admin/companies/createLocalNews',
  updateLocalNews: '/admin/companies/updateLocalNews',
  getLocalNewsConfigOptions: '/admin/companies/getLocalNewsConfigOptions',
  changeNewsConfigOptions: '/admin/companies/changeNewsConfigOptions',
  changeNewsConfigExternalOptions: '/admin/companies/changeNewsConfigExternalOptions',
  createAdvertisement: '/admin/advertisement/createAdvertisement',
  getAdvertisementTypes: '/admin/advertisement/getAdvertisementTypes',
  getCompanyAdvertisement: '/admin/advertisement/getCompanyAdvertisement',
  getAdvertisementDetail: '/admin/advertisement/getAdvertisementDetail',
  createAdvertisementConfig: '/admin/advertisement/createAdvertisementConfig',
  updateAdvertisement: '/admin/advertisement/updateAdvertisement',
  updateAdvertisementConfig: '/admin/advertisement/updateAdvertisementConfig',
  deleteAdvertisementConfig: '/admin/advertisement/deleteAdvertisementConfig',
  deleteAdvertisement: '/admin/advertisement/deleteAdvertisement',
  createAdvertisementStepConfig: '/admin/advertisement/createAdvertisementStepConfig',
  deleteAdvertisementStepConfig: '/admin/advertisement/deleteAdvertisementStepConfig',
  updateAdvertisementStepConfig: '/admin/advertisement/updateAdvertisementStepConfig',
  sendAdvertisementNotification: '/admin/advertisement/sendAdvertisementNotification',
  getAdvertisementActions: '/admin/advertisement/getAdvertisementActions',
  createUser: '/admin/users/createUserForCompany',
  getUserInfo: '/admin/users/getUserInfo',
  updateUserInfo: '/admin/users/updateUserInfo',
  removeUserFromCompany: '/admin/users/removeUserFromCompany',
  searchUserBySearch: '/admin/users/searchUserBySearch',
  customPushNotificationToUsers: '/admin/notifications/customPushNotificationToUsers',
}

export {endpoints};